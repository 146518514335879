import {request} from "@/plugins/axios";

//获取小程序码
export function getScheme(params) {
  return request({
    url: '/drainageLink/getScheme',
    method: 'GET',
    params
  })
}

// 获取页面标题
export function getTitle(params) {
  return request({
    url: '/drainageLink/getTitle',
    method: 'GET',
    params
  })
}
